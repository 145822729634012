import React from 'react'

function Button() {
  return (
    <>
    <a className='a-button' href="#meta-contact-wrapper"><button className='button'>Ta första steget</button></a>
    </>
  )
}

export default Button